<template>
  <div class="home">
    <div class="home__title">
      <div class="home__title-logo"></div>
      C1 DISTRIBUTED DATA SOLUTIONS DMCC
    </div>
    <div class="home__section-about">
      <div class="title">ABOUS US</div>
      <div class="text">C1 DISTRIBUTED DATA SOLUTIONS DMC is a software development and IT services company based in the United Arab Emirates. We specialize<br>in providing open-source software development, support for software solutions of any complexity, CRM and ERP system implementation and maintenance,<br>and IT infrastructure services, all within the framework of our licensed activities.</div>
      <div class="text">Our team is dedicated to delivering high-quality, innovative solutions tailored to meet the diverse needs of our clients. We are committed to maintaining the highest standards of professionalism and ensuring the satisfaction of our partners and customers.</div>
    </div>
    <div class="home__section-about">
      <div class="title">CONTACT US</div>
      <div class="box">
        <div class="controls">
          <div class="title-box">Fill the form and our manager will contact you</div>
          <div class="inputs">
            <div class="inputs-item">
              <div class="placeholder">Enter your name*</div>
              <div class="icon-input list" />
              <input v-model="name" placeholder="input name" class="input" />
            </div>
            <div class="inputs-item">
              <div class="placeholder">Enter your e-mail*</div>
              <div class="icon-input mail" />
              <input v-model="email" placeholder="input e-mail" class="input" />
            </div>
            <div class="inputs-item">
              <div class="placeholder">Additional information</div>
              <div class="icon-input list" />
              <input v-model="description" placeholder="" class="input" />
            </div>
            <div class="send" @click="sendMail"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="home__section-about table">
      <div class="group">
        <div class="item">
          <div class="label">Licence Number</div>
          <div class="value">DMCC-838593</div>
        </div>
        <div class="item">
          <div class="label">Account Number</div>
          <div class="value">346592</div>
        </div>
        <div class="item">
          <div class="label">Registration Number</div>
          <div class="value">DMCC192715</div>
        </div>
      </div>
      <div class="group">
        <div class="item">
          <div class="label">Operating Name</div>
          <div class="value">CITADEL.ONE</div>
        </div>
        <div class="item">
          <div class="label">Legal Status</div>
          <div class="value">Free Zone Company</div>
        </div>
        <div class="item">
          <div class="label">Country of Origin</div>
          <div class="value">United Arab Emirates</div>
        </div>
      </div>
      <div class="group">
        <div class="location">
          <div class="icon-locate"></div>
          Unit No: 1403 The Dome Tower<br>
          Plot No: JLT-PH1-N1 Jumeirah Lakes Towers<br>
          Dubai UAE
          <div class="copyright">© 2024. All rights reserved</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import logoSvg from '@/assets/logo.svg';
import { ref } from 'vue';

export default {
  components: {
    logoSvg,
  },
  setup() {
    const name = ref('');
    const email = ref('');
    const description = ref('');

    const sendMail = () => {
      if (name.value && email.value && email.value.includes('@')) {
        window.open(`mailto:info@citadel.one?subject=From: ${email.value}&body=${name.value}: ${description.value}`);
        
        setTimeout(() => {
          name.value = '';
          email.value = '';
          description.value = '';
        }, 2000);
      }
    };

    return {
      name,
      email,
      description,
      sendMail,
    }
  },
}
</script>
<style lang="scss" scoped>
  $colorGreen: #B8D700;
  $colorMagenta: #B5A3F7;
  $colorWhite: #fff;

  .home {
    width: 1280px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &__title-logo {
      width: 67px;
      height: 83px;
      background-image: url('~@/assets/logo.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      margin-right: 25px;
    }

    &__title {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 48px;
      font-family: Panton_Bold;
    }

    &__section-about {
      margin-top: 50px;

      &.table {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 70px;

        .group {
          display: flex;
          flex-direction: column;

          .item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .label {
              width: 180px;
              color: $colorMagenta;
              font-family: Panton_SemiBold;
              font-size: 18px;
            }

            .value {
              color: $colorWhite;
              font-family: Panton_SemiBold;
              font-size: 18px;
            }
          }

          .icon-locate {
            width: 17px;
            height: 24px;
            background-image: url('~@/assets/marker.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            position: absolute;
            top: -3px;
            left: 70px;
          }

          .copyright {
            color: $colorMagenta;
            font-size: 16px;
            position: absolute;
            right: 0;
            bottom: -35px;
            font-family: Panton_SemiBold;
          }

          .location {
            color: $colorWhite;
            font-family: Panton_SemiBold;
            font-size: 18px;
            display: flex;
            align-items: flex-start;
            text-align: right;
            position: relative;
            line-height: 25px;
          }
        }
      }

      .title {
        color: $colorGreen;
        font-size: 50px;
        font-family: Panton_Bold;
      }

      .text {
        color: #fff;
        margin-top: 25px;
        font-family: Panton_Regular;
        font-size: 18px;
        line-height: 28px;
      }

      .box {
        margin-top: 40px;
        width: 1280px;
        height: 200px;
        border: 2px solid $colorGreen;
        border-radius: 16px;
        padding: 15px 20px;
        box-sizing: border-box;

        .controls {
          background: #1E1E1E;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          align-items: flex-start;
          box-sizing: border-box;
          padding: 30px 20px;

          .inputs {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .send {
              width: 68px;
              height: 68px;
              border-radius: 8px;
              background-color: #7D61ED;
              display: flex;
              background-image: url('~@/assets/send.svg');
              background-repeat: no-repeat;
              background-size: 40px;
              background-position: 50%;
              cursor: pointer;
              transition: all .3s ease;

              &:hover {
                opacity: .7;
              }
            }

            .inputs-item {
              border: 1px solid #5030A0;
              width: 365px;
              height: 68px;
              border-radius: 8px;
              margin-right: 12px;
              position: relative;

              .placeholder {
                position: absolute;
                left: 15px;
                top: 10px;
                font-size: 14px;
                font-family: Panton_SemiBold;
                color: #828282;
              }

              .icon-input {
                position: absolute;
                left: 15px;
                top: 40px;
                width: 13px;
                height: 10px;
                background-repeat: no-repeat;
                background-position: 50%;

                &.list {
                  background-image: url('~@/assets/list.svg');
                }

                &.mail {
                  background-image: url('~@/assets/mail.svg');
                }
              }

              .input {
                position: absolute;
                width: 310px;
                left: 35px;
                top: 31px;
                outline: none;
                border: none;
                background: none;
                color: #fff;
                height: 25px;
              }
            }
          }
        }

        .title-box {
          color: $colorGreen;
          font-size: 24px;
          font-family: Panton_Bold;
        }
      }
    }
  }
</style>
