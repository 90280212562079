<template>
  <router-view/>
</template>

<style lang="scss">
body,html {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-image: url('~@/assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: #444;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
